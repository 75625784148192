<template>
  <b-container>
    <b-card class="mt-5 mb-5 iq-border-radius-20">
      <p v-if="allStoreInfo.about.length > 0" class="about-merchant-store text-black">{{allStoreInfo.about}}</p>
      <p v-else class="about-merchant-store text-black text-center font-weight-bold">لا يوجد</p>
    </b-card>
  </b-container>
</template>

<script>
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'aboutStore',
  data () {
    return {
      allStoreInfo: {}
    }
  },
  methods: {
    getStoreInfo () {
      marketPlace.getStoreInfo(this.$route.params.slogan).then(res => {
        this.allStoreInfo = res.data
      })
    }
  },
  created () {
    this.getStoreInfo()
  }
}
</script>

<style>
.about-merchant-store{
font-size: 22px;
}
@media (max-width: 992px) {
  .about-merchant-store{
    font-size: 16px !important;
  }
}
</style>
